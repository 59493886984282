import revive_payload_client_7NwhnByYeL from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_so8bOwvbe4 from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YDxGjxuKDW from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_b8sNZ2rIm1 from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_xus3qujr3m5mut663jsx24wo24/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_23oru7WsCk from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7FDXsbXjYT from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_N7bZlH51It from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_KiL5lQV9I0 from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_1skUY8bBWB from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_JA3pYbC1eQ from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pdfps63yvxn7ebfx7mtldpmjdy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _00_init_client_77O4tQBcSo from "/usr/src/app/plugins/00.init.client.ts";
import _01_auth_client_UOBMAirzzr from "/usr/src/app/plugins/01.auth.client.ts";
import _03_vuetify_hLVW487wAp from "/usr/src/app/plugins/03.vuetify.ts";
export default [
  revive_payload_client_7NwhnByYeL,
  unhead_so8bOwvbe4,
  router_YDxGjxuKDW,
  _0_siteConfig_b8sNZ2rIm1,
  payload_client_23oru7WsCk,
  navigation_repaint_client_7FDXsbXjYT,
  check_outdated_build_client_N7bZlH51It,
  restore_state_client_KiL5lQV9I0,
  chunk_reload_client_1skUY8bBWB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JA3pYbC1eQ,
  _00_init_client_77O4tQBcSo,
  _01_auth_client_UOBMAirzzr,
  _03_vuetify_hLVW487wAp
]