/// <reference path="../defs/common.d.ts" />

import type {
    Sku,
    AttributeValue,
    ColorOptionGroup,
    LegacyContentItem,
    QueryParamsExtended,
    PaginationParams,
    ColorOption,
    MembershipEntity,
    AttributeValueAttribute,
    GarmentSize,
    FullImage,
    Logo
} from './';

export enum ProductAssortmentType {
    New = 'new',
    Carryover = 'carryover',
    Discontinued = 'discontinued'
}

export enum ProductAvailabilityStatus {
    ComingSoon = 'coming_soon',
    Booking = 'booking',
    Preorder = 'preorder',
    Available = 'available',
    Backorder = 'backorder',
    SoldOut = 'sold_out',
    Discontinued = 'discontinued'

    // Not currently used on the front end
    // Unavailable = 'unavailable'
}


export enum ProductFlagOption {
    best_seller = 'Best Seller',
    new = 'New',
    sale = 'Sale'
}

export type ProductFlag = keyof typeof ProductFlagOption;


/**
*  @deprecated
*/
export enum ProductBadge {
    best_seller = 'Best Seller',
    new = 'New',
    sale = 'Sale'
}

export type ProductOptionsModel = Record<string, string>;
export type ProductOrderedOptionsModel = string[];

export interface ProductRetailUrl {
    url: string;
    // TODO: Fix me!
    org: Partial<MembershipEntity>;
    attributeValues: AttributeValue[];
}


export interface ProductService {
    cost: number;
    description: string;
    label: string;
    uid: string;
}



export interface ProductWarrantyPolicy {
    allowUpgrade: boolean;
    covered: string[];
    name: string;
    notCovered: string[];
}

/**
*  @deprecated
*/
export interface CategoryWarrantyPolicy {
    covered: string[];
    name: string;
    notCovered: string[];
}

/**
*  @deprecated
*/
export interface CategoryOnProduct {
    name: string;
    uid: string;
    warrantyPolicy: CategoryWarrantyPolicy;
}

export enum ProductWarrantyType {
    'No warranty',
    '2M' = '2-month warranty',
    '3M' = '3-month warranty',
    '6M' = '6-month warranty',
    '1Y' = '1-year warranty'
}

export type ProductAttributeType = 'option' | 'tag';

export interface ProductImagesBySizeType {
    full: string;
}

/**
*  @deprecated
*/
export interface ProductAttribute {
    uid: string;
    sortOrder: number;
    values: AttributeValue[];
    isVisible: boolean;
    isVariable: boolean;
    isSpec: boolean;
    attributeType: ProductAttributeType;
    attribute: AttributeValueAttribute;
    recommendedValue?: Nullable<AttributeValue>;
}

/**
*  @deprecated
*/
export interface ProductOptionItem {
    sortOrder: number;
    attribute: AttributeValueAttribute;
    recommendedValueUid?: Nullable<string>;
    values: AttributeValue[];
}

/**
*  @deprecated
*/
export type ProductOption = ProductAttribute;




// Product Retail

export interface ProductRetailerUrl {
    options: AttributeValue[];
    url: string;
}

export interface ProductRetailer {
    sortOrder: number;
    org: Partial<MembershipEntity>;
    urls: ProductRetailerUrl[];
}

export type ProcessedProductRetailer = [org: Partial<MembershipEntity>, urls: ProductRetailerUrl[]];

// Product Pricing

export interface ProductPricePoint {
    name: string;
    price: number;
    url: string;
    imageUrl: string;
}

export interface ProductPrice {
    price: number;
    description: string;
}


// Product Groups

export type ProductAttrsGroupType = 'option' | 'spec';

export interface ProductAttrsSkuOption {
    value: AttributeValue;
}

export interface ProductAttrsGroupBase {
    sortOrder: number;
    attribute: AttributeValueAttribute;
    groupValues: ProductAttrsSkuOption[];
    isProminent?: boolean;
    showOnCollectionPages?: boolean;
}

export interface ProductAttrsGroupOption extends ProductAttrsGroupBase {
    recommendedValueUid: Nullable<string>;
}

export interface ProductAttrsGroupSpec extends ProductAttrsGroupBase {
    isVisible: boolean;
}


// Product

export interface Product {

    /**
    *  @deprecated
    */
    badge: Nullable<keyof typeof ProductBadge>;
    flags: Nullable<ProductFlag[]>;


    discount: Nullable<number>;
    services: ProductService[];
    retailUrls: ProductRetailUrl[];

    /**
    *  @deprecated
    */
    productAttributes: ProductAttribute[];

    // TODO: This needs to be researched...
    // category: CategoryOnProduct;
    category?: Partial<ProductCategory>;

    skus: Sku[];
    features?: Nullable<LegacyContentItem[]>;
    archived: boolean;
    categoryLayoutId: string;
    contactEmail: string;
    description: string;
    discontinuedDate: string;
    genders: string[];
    gender: Nullable<string>;
    h1: string;
    hashtag: string;
    defaultSkuUid: string;
    isGarment: boolean;
    isPpe: boolean;
    launchDate: Nullable<string>;
    launchTimeStr: Nullable<string>;
    isLaunchDateConfirmed: boolean;
    maxPrice: number;
    minPrice: number;
    name: string;
    shortName: Nullable<string>;
    notNewDate: Nullable<string>;
    requestFormUrl: string;
    requestText: string;
    requiresContainer: string;
    shortDescription: string;
    seoDescription: string;
    serialCodeVersions: string[];
    shipDateEnd: Nullable<string>;
    shipDateStart: Nullable<string>;
    skuCount: number;
    slug: string;
    specs: string;
    tagline: string;
    termsId: number;
    uid: string;
    warrantyLength: string;
    youtubeId: Nullable<string>;
    videoUrl?: Nullable<string>;


    // NOTE: *** Might be missing ***
    //       Need to confirm if these are going to be standard props
    preorderDate?: Nullable<string>;
    status?: Nullable<string>;
    assortmentType?: Nullable<ProductAssortmentType>;


    /**
    *  @deprecated use product.path
    */
    productPath: string;
    path: string;

    // Expanders
    optionGroups: ProductAttrsGroupOption[];
    specGroups: ProductAttrsGroupSpec[];
    logo?: Nullable<Logo>;
    id?: string;
    retailers?: ProductRetailer[];
    garmentSizes?: GarmentSize[];
    prices?: ProductPrice[];
    reviewCount?: number;
    averageRating?: number;
    defaultImage?: Nullable<FullImage>;
    defaultSku?: Nullable<Sku>;
    productFeatures?: Nullable<Partial<LegacyContentItem>[]>;
    productInfoSheet?: Nullable<string>;
    marketingAssetsUrl?: Nullable<string>;

    /**
    *  @deprecated
    */
    options?: Nullable<ProductOptionItem[]>;
}


/**
*  @deprecated
*/
export interface ProductMarketingItem {
    uid: string;
    name: string;
    image: Nullable<string>;
    imageAltText: Nullable<string>;
    imageHeight: Nullable<string>;
    imageWidth: Nullable<string>;
    productImagesUrl: Nullable<string>;
    description: Nullable<string>;
    youtubeId: string;
    marketingAssetsUrl: Nullable<string>;
    productPath: string;
    productInfoSheet: Nullable<string>;
    preorderDate: Nullable<string>;
    launchDate: Nullable<string>;
    isLaunchDateConfirmed: Nullable<string>;
    genders: string[];
    gender: Nullable<string>;
    category?: Partial<ProductCategory>;
    warrantyLength: string;
    features?: Nullable<LegacyContentItem[]>;
    shortDescription: string;
    tagline: string;
}

export interface ProductBundle {
    uid?: string;
    skuPk: string;
    skuName: string;
    signature: string;
}

export interface ProductCategoryItem {
    uid: string;
    name: string;
    path: string;
}

export interface ProductCategory {
    uid: string;
    name: string;
    path: string;
    fullName: string;
    sortOrder: number;

    // Expanders
    attributes?: AttributeValue[];
    market?: Partial<ProductMarket>;
    products?: Nullable<ProductCategoryItem[]>;
    warrantyPolicy?: Nullable<ProductWarrantyPolicy>;
}


export interface ProductMarket {
    uid: string;
    name: string;
    sortOrder: number;
    categories?: Nullable<ProductCategory[]>;
    path: string;


    // TODO: REMOVE: Probably never used
    /**
    *  @deprecated
    */
    shortCode: string;
    /**
    *  @deprecated
    */
    contactEmail: string;
    /**
    *  @deprecated
    */
    titleDescription: string;
    /**
    *  @deprecated
    */
    metaDescription: string;
}





export interface ProductCategoryRequestParams {
    market_uid: string;
}

export interface MarketCategorySelection {
    markets: string[];
    categories: string[];
}

// List / Collection

export type ProductFilterByType = 'market' | 'category' | 'collection';

export interface ProductsFilterParamsObj extends QueryParamsExtended, Partial<PaginationParams> {
    market?: Nullable<string>;
    category?: Nullable<string>;
    f?: Nullable<string[]>;
    is_filter?: boolean;
}

export interface ProductListUpdateObj {
    shown: number;
    total: number;
}

// Filter

export interface ProductFilterAttributeValue {
    uid?: string;
    name: string;
    description: string;
    colors: string[];
}

export interface ProductFilterAttribute {
    values: ProductFilterAttributeValue[];
    uid?: string;
    name: string;
    type: string;
}

export interface ProductFilterAttributeItem {
    uid: string;
    values: string[];
}

export type ProductFilterTrackingObj = Record<string, Nullable<ProductFilterAttributeItem>>;

export interface IProductVersionItem {
    label: string;
    format?: RegExp;
    maxLength?: number;
}

export type IProductVersions = Record<string, IProductVersionItem>;



// TODO: Move this to a separate file
export interface ColorAttributeValue {
    colors: string[];
    description: string;
    name: string;
    sortOrder: number;
    uid: string;
}


// Product Filters

export type ProductFilterModelItem = Record<string, boolean>;
export type ProductFilterModel = Record<string, ProductFilterModelItem>;



// Product Catalog

export interface ProductCatalogMenuSelection {
    name: string;
    uid: string;
    path: string;
}
